import i18n from "i18next"
import { initReactI18next } from "react-i18next"

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  resources: {
    en: {
      translations: require("./translations.en.json"),
    },
    fr: {
      translations: require("./translations.fr.json"),
    },
    es: {
      translations: require("./translations.es.json"),
    },
    pt: {
      translations: require("./translations.pt.json"),
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
  returnObjects: true,
  debug: false, //process.env.NODE_ENV === "development",
  interpolation: {
    escapeValue: false, // not needed for react!!
  },
})

i18n.languages = ["de", "en", "fr", "dk", "es"]

export default i18n
